import React from 'react';


const CV = () => {

  return (
    <>
    <div className="cv-container">

      <div className="cv">
      </div>

    </div>
    </>
  );
};



export default CV;
