export const MenuItems = [
  {
    title: 'Home',
    url: '/',
    cName: 'nav-links'
  },
  {
    title: 'About',
    url: '/About',
    cName: 'nav-links'
  },
  {
    title: 'Projects',
    url: '/Projects',
    cName: 'nav-links'
  },
  {
    title: 'Contact',
    url: '/Contact',
    cName: 'nav-links'
  }

]
